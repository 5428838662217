@tailwind base;
@tailwind components;
@tailwind utilities;

/* color themes */
@layer base {
  :root {
    --background: 210 40% 96.1%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 215.4 16.3% 46.9%;

    --primary: 222.2 47.4% 11.2%;

    --secondary: 313 72% 48%;
    --secondary-foreground: 245 44% 90%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 223 47% 11%;
    --foreground: 213 31% 96%;

    --muted: 215.4 16.3% 68%;

    --primary: 210 40% 98%;
  }

  .purple {
    --accent: 245 34% 55%;
    --secondary: 313 72% 44%;
    --secondary-foreground: 245 44% 90%;
  }

  .dark-purple {
    --accent: 243 34% 61%;
    --secondary: 313 72% 54%;
    --secondary-foreground: 245 44% 90%;
  }

  .red {
    --accent: 353 86% 46%;
    --secondary: 307 50% 40%;
    --secondary-foreground: 339 52% 94%;
  }

  .dark-red {
    --accent: 353 86% 58%;
    --secondary: 282 67% 61%;
    --secondary-foreground: 339 52% 94%;
  }

  .green {
    --accent: 176 100% 19%;
    --secondary: 176 96% 31%;
    --secondary-foreground: 216 44% 85%;
  }

  .dark-green {
    --accent: 176 100% 28%;
    --secondary: 149 56% 53%;
    --secondary-foreground: 216 44% 85%;
  }
  .blue {
    --accent: 238 100% 50%;
    --secondary: 212 92% 45%;
    --secondary-foreground: 221 100% 92%;
  }

  .dark-blue {
    --accent: 228 100% 66%;
    --secondary: 185 84% 52%;
    --secondary-foreground: 221 100% 92%;
  }

  .coral {
    --accent: 353 61% 60%;
    --secondary: 33 84% 51%;
    --secondary-foreground: 9 100% 92%;
  }

  .dark-coral {
    --accent: 353 61% 64%;
    --secondary: 33 84% 54%;
    --secondary-foreground: 9 80% 92%;
  }

  main {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .app {
    min-height: 100vh;
    box-sizing: border-box;
    display: block;
    position: relative;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 10vh;
    padding-bottom: 10vh;
    font-family: "Poppins", sans-serif;
    cursor: default;
  }

  h1 {
    @apply text-accent text-2xl md:text-3xl font-bold tracking-wide;
  }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
  .app {
    padding-right: 8vw;
    padding-left: 8vw;
  }
}
